<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckoutReactive, useCheckoutRoutes } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePayId, PayIdOrder } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import CompletePayId from '/~/views/checkout.v2/views/checkout/components/complete-payid/complete-payid.vue'

const router = useRouter()
const route = useRoute()
const { getCurrentFlowRouteName } = useCheckoutRoutes()
const { payment, currentFlowType, getFlowTypeFromOrderNumber } =
  useCheckoutReactive()
const { onCancelPaymentOrder } = usePayId()
const { isBillPaymentsV15Template } = useProvider()

const props = defineProps<{
  orderId: string
}>()

const payIdOrder = computed(() => {
  return new PayIdOrder({
    id: payment.value.confirmation.order?.paymentMethods?.[0].name,
    reference: props.orderId,
    amount: payment.value.confirmation.order?.total,
    expiresAt: payment.value.confirmation.order?.expiresAt,
  })
})
const loading = ref(false)

function getConfirmationRoute() {
  if (isBillPaymentsV15Template.value) {
    switch (currentFlowType.value) {
      case FlowType.batch:
        return 'payments-confirmation-batch'
      default:
        return 'payments-confirmation'
    }
  }

  return getCurrentFlowRouteName('checkout-confirmation')
}

function goToConfirmationPage() {
  modal.hideAll()

  router.push({
    name: getConfirmationRoute(),
    params: { orderId: props.orderId },
  })
}

function goToActivityPage() {
  router.push({
    name: 'activity',
  })
}

onBeforeMount(async () => {
  try {
    loading.value = true
    currentFlowType.value = getFlowTypeFromOrderNumber(props.orderId)
    await payment.value.getOrder(props.orderId)
    const status = payment.value.confirmation.order?.status

    if (status && status !== 'awaiting_funds_pi') {
      goToConfirmationPage()
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
})

emitter.on('notifications:payment_order_status_updated', (event) => {
  const { orderNumber, orderStatus } = event

  if (orderStatus === 'awaiting_funds_pi') {
    return
  }

  if (orderNumber !== props.orderId) {
    return
  }

  const completePayIdRouteName = getCurrentFlowRouteName('complete-payid')

  if (route.name === completePayIdRouteName) {
    goToConfirmationPage()
  }
})

const handleAboutPayIDClick = () => {
  modal.show('about-payid', {
    on: {
      close: () => {
        modal.hide('about-payid')
      },
    },
  })
}
</script>

<template>
  <div class="h-full overflow-auto bg-gray-50 pt-4 sm:pt-8">
    <div class="mx-auto h-full max-w-3xl rounded-t-3xl p-8">
      <div class="flex items-center justify-between">
        <div class="text-center text-3xl font-bold">
          Complete payment with PayID
        </div>
        <base-button look="link" @click="handleAboutPayIDClick">
          <base-icon
            :svg="'heroicons/mini/information-circle'"
            :size="24"
            class="mr-2"
          />
          <span class="text-base underline underline-offset-2 md:text-lg">
            About PayID
          </span>
        </base-button>
      </div>
      <complete-pay-id
        class="mt-8"
        :pay-id-order="payIdOrder"
        :loading="loading"
        @cancel="
          onCancelPaymentOrder(orderId, goToConfirmationPage, payment.flowType)
        "
        @continue="goToActivityPage()"
      />
    </div>
  </div>
</template>
